/* ----------------------------------------
 * ページトップへ
---------------------------------------- */
.l-pageTop {
  transition-duration: .24s;
  transition-property: opacity, visibility;
  text-align: center;

  &__btn {
    display: block;
    white-space: nowrap;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.2;
    color: var(--color-white);
    margin: 0 auto;

    @media (--mq-tab) {
      font-size: 1.4rem;
      outline: none;

    }
  }
}
