/* about */
.p-about {
  &TopSect {
    background-color: #fff;
    position: relative;
    padding: 35px 2.5% 57px;

    @media (--mq-tab) {
      max-width: var(--width-max);
      margin: 0 auto;
      padding: 100px 2.5% 75px;
    }
  }

  &Top {
    &__block {
      margin-bottom: 45px;

      @media (--mq-tab) {
        margin-bottom: 120px;

        &:last-child {
          margin-bottom: 104px;
        }
      }
    }

    &__lead {
      font-size: 1.4rem;
      line-height: 1.8;
      text-align: center;

      @media (--mq-tab) {
        text-align: left;
        line-height: 2.8;
      }
    }

    &__txtImg {
      width: 90%;
      margin: 44px auto 50px;

      @media (--mq-tab) {
        width: auto;
        max-width: 100%;
        margin: 77px 0 0;
      }
    }
  }

  /* 関連企業 */
  &Relation {
    padding: 20px 0 31px;

    &List {
      display: flex;
      flex-direction: column;

      @media (--mq-tab) {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }

      &__item {
        text-align: center;
        margin-bottom: 14px;

        @media (--mq-tab) {
          margin-bottom: 0;
          margin-right: 63px;
        }
      }

      &__item:nth-of-type(1) {
        margin-bottom: 7px;

        @media (--mq-tab) {
          margin-bottom: 0;
        }
      }

      &__item:nth-of-type(2) {
        margin-bottom: 21px;

        @media (--mq-tab) {
          margin-bottom: 0;
        }
      }
    }
  }

  /* history */
  &History {
    &Sect {
      /*overflow: hidden;*/
      background:url(../img/about/history_bg_sp.png);
      background-repeat: repeat-y;
      background-size: 100% auto;
      position: relative;
      padding-right: 2.5%;
      padding-left: 2.5%;

      @media (--mq-tab) {
        background: none;
        background-color: rgba(70, 18, 18, 1);
      }

      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 24px;
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #454545;
        z-index: 10;

        @media (--mq-tab) {
          background-color: #b4b4b4;
        }
      }
    }
  }

  &History {
    position: relative;

    @media (--mq-tab) {
      max-width: var(--width-max);
      margin: 0 auto;

      /* コンテンツ外の画像 */
      &::before,
      &::after {
        content: '';
        display: block;
        width: 532px;
        height: 100%;
        position: absolute;
        top: 0;
      }

      &::before {
        left: -582px;
        background-image: url("../img/about/history_bg_l.png");
        background-repeat: repeat-y;
      }

      &::after {
        right: -582px;
        background-image: url("../img/about/history_bg_r.png");
        background-repeat: repeat-y;
      }
    }

    &__inner {
      padding-top: 30px;
      padding-bottom: 57px;

      @media (--mq-tab) {
        padding-top: 100px;
        padding-bottom: 75px;
      }
    }

    &__lists {

    }

    &__ttl {
      overflow: hidden;
      font-size: 50px;
      font-weight: bold;
      font-style: italic;
      opacity: .3;
      color: rgba(70, 18, 18, 1);
      text-shadow: white 1px 1px 0, white -1px 1px 0,
      white 1px -1px 0, white -1px -1px 0;
      position: relative;

      @media (--mq-tab) {
        font-size: 100px;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, .3);
        position: absolute;
        top: 35px;
        left: 170px;
        z-index: 100;

        @media (--mq-tab) {
          top: 72px;
          left: 320px;
        }
      }

      &--s::after {
        left: 280px;

        @media (--mq-tab) {
          left: 520px;
        }
      }
    }

    &__cont {
      font-size: 1.2rem;
      line-height: 1.5;
      font-weight: 200;
      color: #fff;
      margin-top: -25px;
      margin-bottom: 30px;

      @media (--mq-tab) {
        font-size: 1.4rem;
        line-height: 1.6;
        margin-top: -48px;
      }
    }
  }
}

