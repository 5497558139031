:root {
  /* ----------------------------------------
  * color
  ---------------------------------------- */
  --color-key-main: #ffc700;
  --color-key-main-lighter: #eed476;
  --color-key-main-dark: #e7a600;
  --color-key-main-shadow: #d9a900;
  --color-white: #fff;
  --color-black: #000;
  --color-link-sub: #565656;
  --color-bg1-gray: #fafafa;
  --color-bg2-key: #fffcf1;
  --color-bg3: #fff;
  --color-bg4: #f0f0f0;
  --color-bg5: #2e2921;
  --color-secbtn-shadow: #d9d9d9;
  --color-txt2: #fff;
  --color-bk2: #4b4233;
  --color-txt-main: #2e2921;
  --color-line: #d7d7d7;
  --color-light: #d3cabb;
  --color-ttl2-bg: #ffe68e;

  /* accent */
  --color-accent1: #2eb975;
  --color-accent1-shadow: #279d64;
  --color-accent2: #ff7200;
  --color-accent2-shadow: #d96100;

  /* ----------------------------------------
  * size
  ---------------------------------------- */

  /* width */
  --width-min: 768px;
  --width-mid: 960px;
  --width-max: 1318px;

  /* font */
  --font-size-base: 1.4rem;

  /* --------------------------.
  ww--------------
  * border radius
  ---------------------------------------- */
  --bd-radius: 6px;

  /* ----------------------------------------
  * duration
  ---------------------------------------- */
  --duration-base: .4s;

  /* ----------------------------------------
  * hover
  ---------------------------------------- */
  --hover-text: .5;
}
