/* product */
.c-prodHeader {
  background-color: var(--color-black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media (--mq-tab) {
    background: none;
    position: relative;
    margin-bottom: 98px;
  }

  &__inner {
    width: 100%;
    min-height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--mq-tab) {
      justify-content: flex-start;
    }
  }

  &__ttl {
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 1.2;
    color: var(--color-white);
    position: relative;

    @media (--mq-tab) {
      font-size: 4.3rem;

      &::after {
        content: '';
        width: 72px;
        height: 2px;
        background-color: #C40000;
        position: absolute;
        bottom: -30px;
        left: 0;
      }
    }
  }
}
