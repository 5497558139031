/* title */
.c-ttl {
  &--lv1 {
    font-size: 2.1rem;
    font-weight: bold;
    line-height: 1.1;
    position: relative;
    text-align: center;
    margin-bottom: 25px;

    @media (--mq-tab) {
      font-size: 2.4rem;
      margin-bottom: 56px;
      text-align: left;
    }

    &::after {
      content: '';
      width: 72px;
      height: 2px;
      background-color: #C40000;
      position: absolute;
      bottom: -14px;
      left: 50%;
      transform: translateX(-50%);

      @media (--mq-tab) {
        font-size: 2.4rem;
        line-height: 1.2;
        left: 0;
        bottom: -23px;
        transform: translateX(0%);
      }
    }
  }

  &--about {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    position: relative;
    text-align: center;
    margin-bottom: 40px;

    @media (--mq-tab) {
      font-size: 2.8rem;
      margin-bottom: 56px;
      text-align: left;
    }

    &::after {
      content: '';
      width: 72px;
      height: 2px;
      background-color: #C40000;
      position: absolute;
      bottom: -14px;
      left: 50%;
      transform: translateX(-50%);

      @media (--mq-tab) {
        font-size: 2.4rem;
        line-height: 1.2;
        left: 0;
        bottom: -23px;
        transform: translateX(0%);
      }
    }
  }

  &--prodDetail {
    position: relative;
    font-size: 2.3rem;
    line-height: 1.2;
    text-align: left;
    color: var(--color-white);
    margin-bottom: 25px;

    @media (--mq-tab) {
      font-size: 4.5rem;
      margin-bottom: 10px;
    }

    &::after {
      content: '';
      width: 72px;
      height: 2px;
      background-color: #C40000;
      position: absolute;
      bottom: -10px;
      left: 0;

      @media (--mq-tab) {
        content: none;
      }
    }
  }

  &--outline {
    color: #292929;
    text-shadow: white 1px 1px 0, white -1px 1px 0px,
    white 1px -1px 0, white -1px -1px 0;
  }

  &--comp {
    font-size: 1.4rem;
    line-height: 1.1;
    position: relative;
    text-align: center;
    margin-bottom: 25px;

    @media (--mq-tab) {
      font-size: 2.4rem;
      margin-bottom: 53px;
    }

    &::after {
      content: '';
      width: 72px;
      height: 2px;
      background-color: #C40000;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);

      @media (--mq-tab) {
        width: 100px;
        font-size: 2.4rem;
        line-height: 1.2;
        bottom: -32px;
      }
    }
  }




}
