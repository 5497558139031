/* ----------------------------------------
 * 基本設定
---------------------------------------- */
html {
  position: relative;
  color: var(--color-txt-main);
  font-family: 'Prompt', 'Noto Sans JP', sans-serif;
  font-size: 62.5%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1.4rem;
  line-height: 1.5;

  @media (--mq-tab) {
    font-size: var(--font-size-base);
  }
}

/* リンク */
a {
  color: var(--color-txt-main);
}

/* 画像 */
img {
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}

/* リスト */
ol,
ul {
  list-style: none;
}

/* 整形済みテキスト */
pre {
  overflow: auto;
  padding: 1em;
  margin-bottom: 24px;
  color: #fff;
  font-size: 1.4rem;
  background-color: #364549;
}

.comingsoon {
  width: var(--width-sp);
  margin: 0 auto;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
