/* ----------------------------------------
 * media query
---------------------------------------- */
/* Header */
/* SP ~ 959px */
@custom-media --mq-sp--h screen and (max-width: 959px);
/* PC 960px ~ */
@custom-media --mq-pc--h screen and (min-width: 960px);
/* PC 1160px ~ */
@custom-media --mq-pc--hl screen and (min-width: 1160px);

/* sp */
@custom-media --mq-sp--s screen and (min-width: 320px);
@custom-media --mq-sp screen and (min-width: 425px);

/* tab */
@custom-media --mq-tab screen and (min-width: 768px);

/* pc */
@custom-media --mq-pc screen and (min-width: 960px);
