/* top */
.p-topHero {
  width: 100%;
  background-image: url(../img/top/mv_bg.png);
  background-color: #222;
  background-size: cover;
  background-position: center center;
  position: relative;

  &::before {
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: 0;

    @media (--mq-pc) {
      content: none;
    }
  }

  &__inner {
    max-width: var(--width-min);
    height: 297px;
    margin: 0 auto;

    @media (--mq-tab) {
      height: 100vh;
    }

  /*layout*/
    display: flex;
    align-items: center;
    justify-content: center;

  /* text */
    font-size: 2rem;
    color: #fff;
  }

  &__logo {
    position: relative;
    z-index: 1;

    @media (--mq-pc) {
      display: none;
    }
  }
}

/* brand */
.p-topBrand {
  padding: 36px 2.5% 48px;
  background-color: #111;

  @media (--mq-tab) {
    background: radial-gradient(ellipse at top, #fff -220%, #222 40%);
    padding: 100px 0;
  }

  &__logo {
    text-align: center;
    margin-bottom: 20px;
  }

  &__desc {
    /*width: 778px;*/
    margin: 0 auto 25px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 300;
    line-height: 1.8;
    text-align: center;
    color: var(--color-white);
  }

  &__btn {
    max-width: 300px;
    margin: 0 auto;
  }
}

/* products - home */
.p-topProdHome {
  &Sect {
    background-color: #2e2e2e;
    position: relative;
    padding-top: 30px;

    @media (--mq-tab) {
      padding-top: 100px;
    }

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 24px;
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #454545;
      z-index: 10;
    }
  }

  &List {
    display: flex;
    flex-direction: column;

    @media (--mq-tab) {
      flex-direction: row;
    }

    &__item {
      width: 100%;

      @media (--mq-tab) {
        width: calc(100% / 5);
        overflow: hidden;
        background-position: center center;
      }
    }

    @media (--mq-tab) {
      &__item:nth-of-type(1) &__link {
        background-image: url(../img/top/home-yoga-fitness-txt.png);
        background-size: 80px auto;
        background-position:
      }

      &__item:nth-of-type(2) &__link {
        background-image: url(../img/top/home-training-txt.png);
        background-size: 110px auto;
      }

      &__item:nth-of-type(3) &__link {
        background-image: url(../img/top/home-ems-txt.png);
        background-size: 110px auto;
      }

      &__item:nth-of-type(4) &__link {
        background-image: url(../img/top/home-conditioning-txt.png);
        background-size: 110px auto;
      }

      &__item:nth-of-type(5) &__link {
        background-image: url(../img/top/home-protein-suppliment-txt.png);
        background-size: 88px auto;
      }
    }

    &__link {
      width: 100%;
      height: 130px;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      color: #fff;
      transition: all 1.5s ease-out;

      @media (--mq-tab) {
        height: 1045px;
      }
    }

    &__item {
      position: relative;
      background-size: 100%;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .58);
        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s;
      }

      @media (--mq-tab) {
        &:hover::before {
          content: none;
        }
      }

      &:nth-of-type(1) {
        background-image: url(../img/top/home-fitness-bg.png);

        @media (--mq-tab) {
          background-image: url(../img/top/home-fitness-bg.png);
          background-size: cover;
          background-position: center center;
        }
      }

      &:nth-of-type(2) {
        background-image: url(../img/top/home_newproduct_bg_sp.png);

        @media (--mq-tab) {
          background-image:url(../img/top/home-newproduct-bg.png);
          background-size: cover;
          background-position: center center;
        }
      }

      &:nth-of-type(3) {
        background-image: url(../img/top/home-ems-bg.png);

        @media (--mq-tab) {
          background-image: url(../img/top/home-ems-bg.png);
          background-size: cover;
          background-position: center center;
        }
      }

      &:nth-of-type(4) {
        background-image: url(../img/top/home-others-bg.png);

        @media (--mq-tab) {
          background-image: url(../img/top/home-others-bg.png);
          background-size: cover;
          background-position: center center;
        }
      }

      &:nth-of-type(5) {
        background-image: url(../img/top/home-protein-suppliment-bg.png);

        @media (--mq-tab) {
          background-image: url(../img/top/home-protein-suppliment-bg.png);
          background-size: cover;
          background-position: center center;
        }
      }
    }

    &__txt {
      font-size: 2.2rem;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 200;
      white-space: nowrap;

      @media (--mq-tab) {
        font-size: 2.3rem;
        white-space: inherit;
        display: block;
        width: 100%;
        padding: 0 5%;
        text-align: center;
        z-index: 1000;
      }
    }

    &__border {
      content: '';
      width: 80px;
      height: 2px;
      position: absolute;
      background-color: #c40000;
      transform: rotate(-45deg);
      transform-origin: center;
      transition: all .5s;
      z-index: 100;

      @media (--mq-tab) {
        width: 320px;
        height: 2px;
        position: relative;
        background-color: #c40000;
        transform: rotate(-45deg);
        transform-origin: center;
        transition: all .5s;
        z-index: 100;
      }
    }
    @media (--mq-tab) {
      &__link:hover &__border {
        width: 100px;
        transform: rotate(-45deg);
        transform-origin: center;
        transition: all .5s;
      }
    }

    /*&__link::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .58);
      position: absolute;
      top: 0;
      left: 0;
      transition: all .3s;
    }*/

    @media (--mq-tab) {
      &__link:hover::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(82, 4, 4, .58);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/* products - large */
.p-topProdLargeSect {
  background: linear-gradient(
    90deg,
    rgba(51, 51, 51, 1) 0%,
    rgba(51, 51, 51, 1) 50%,
    rgba(46, 46, 46, 1) 50%,
    rgba(46, 46, 46, 1) 100%
  );
  padding-top: 30px;
  padding-bottom: 37px;

  @media (--mq-tab) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.p-topProdLargeTtl {
  width: 100%;
  margin-bottom: 100px;

  &__inner {
    max-width: var(--width-max);
    margin: 0 auto;
  }
}

.p-topProdLarge {
  &ListWrap {
    padding: 0 2.5%;

    @media (--mq-tab) {

    }
  }

  &List {
    /*width: 960px;*/
    display: flex;
    flex-direction: column;
    margin: 0 auto 40px;


    @media (--mq-tab) {
      max-width: var(--width-max);
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto 80px;
    }

    &__item {
      width: 100%;
      height: 95px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (--mq-tab) {
        width: 32%;
        height: auto;
        overflow: hidden;
        margin-bottom: 0;
      }
    }

    &__link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      font-size: 1.8rem;
      text-decoration: none;
      color: #fff;
      transition:all 0.6s ease;
      /*background-image: url(../img/top/large_productname_bg_sp.png);*/
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      & img {
        width: 100%;
        height: auto;
      }

      @media (--mq-tab) {
        padding-top: 81%;
        /*background-image: url(../img/top/large_productname_bg_pc.png);*/

        &::after {
          content: '';
          display: block;
          opacity: 0;
          visibility: hidden;
          transition:all .3s ease;
        }

        &:hover {
          position: relative;
          z-index: 100;

          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    &__txt {
      font-weight: bold;
      position: absolute;
      z-index: 1000;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--mq-tab) {
        top: 0;
        right: -30%;
        opacity: 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition:all 0.4s ease;
        padding: 30px;
      }
    }

    &__link:hover &__txt {
      right: 0;
      opacity: 1;
    }
  }
}

/* movie */
.p-topMovie {
  &Sect {
    background-color: #ccc;
    padding: 30px 2.5% 35px;

    @media (--mq-tab) {
      padding: 100px 2.5% 120px;
    }
  }

  &__desc {
    max-width: 732px;;
    margin: 0 auto 35px;

    @media (--mq-tab) {
      line-height: 2.8;
      text-align: center;
      margin: 0 auto 80px;
    }
  }

  &__inner {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 1075px;
    margin: 0 auto;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* topics */
.p-topTopics {
  &Sect {
    background-color: #eaeaea;
    position: relative;
    padding: 30px 2.5% 57px;

    @media (--mq-tab) {
      padding: 100px 2.5% 75px;
    }

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 24px;
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #707070;
      z-index: 10;
    }
  }

  &List {
    @media (--mq-tab) {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      margin-bottom: 23px;

      @media (--mq-tab) {
        width: 47%;
        margin-right: 6%;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-bottom: 43px;
      }
    }

    &__link {
      display: block;
      text-decoration: none;

      @media (--mq-tab) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__thumb {
    width: 100%;
    margin-bottom: 10px;

    & > img {
      width: 100%;
      height: auto;
    }

    @media (--mq-tab) {
      width: 41%;
      height: auto;
    }
  }

  &__cont {
    @media (--mq-tab) {
      width: 56%;
    }
  }

  &__date {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #898989;
    margin-bottom: 5px;
  }

  &__ttl {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: #0f0f0f;
    margin-bottom: 5px;
  }

  &__desc {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  &__btn {
    max-width: 300px;;
    margin: 0 auto;
  }
}
