/*

 SP Header

 */
@media (--mq-sp--h) {
  .l-headerSp {
    position: fixed;
    justify-content: flex-end;
    z-index: 3000;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;

    &__inner {
      width: 100%;
      height: 100%;
    }
  }

  /* PC Header Hidden */
  .l-header--pc {
    display: none;
  }
}

/* Navigation Button */
.l-headerSpNavBtn {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 10;
  text-align: center;
  padding-top: -5px;

  &__inner {
    position: relative;
    width: 29px;
    height: 21px;
    cursor: pointer;
  }

  &__inner span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #9d9d9d;
  }

  &__inner, &__inner span {
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
  }

  &__inner span:nth-of-type(1) {
    top: 0;
  }

  &__inner span:nth-of-type(2) {
    top: 9px;
  }

  &__inner span:nth-of-type(3) {
    bottom: 0;
  }

  &__inner span:nth-of-type(1) {
    -webkit-animation: btn07-bar01 .75s forwards;
    animation: btn07-bar01 .75s forwards;
  }

  @-webkit-keyframes btn07-bar01 {
    0% {
      -webkit-transform: translateY(9px) rotate(45deg);
    }
    50% {
      -webkit-transform: translateY(9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }

  @keyframes btn07-bar01 {
    0% {
      transform: translateY(9px) rotate(45deg);
    }
    50% {
      transform: translateY(9px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  &__inner span:nth-of-type(2) {
    transition: all .25s .25s;
    opacity: 1;
  }

  &__inner span:nth-of-type(3) {
    -webkit-animation: btn07-bar03 .75s forwards;
    animation: btn07-bar03 .75s forwards;
  }

  @-webkit-keyframes btn07-bar03 {
    0% {
      -webkit-transform: translateY(-9px) rotate(-45deg);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(0) rotate(0);
    }
  }
  @keyframes btn07-bar03 {
    0% {
      transform: translateY(-9px) rotate(-45deg);
    }
    50% {
      transform: translateY(-9px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  &__inner.is-close span:nth-of-type(1) {
    -webkit-animation: active-btn07-bar01 .75s forwards;
    animation: active-btn07-bar01 .75s forwards;
  }
  @-webkit-keyframes active-btn07-bar01 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(9px) rotate(45deg);
    }
  }
  @keyframes active-btn07-bar01 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(9px) rotate(0);
    }
    100% {
      transform: translateY(9px) rotate(45deg);
    }
  }
  &__inner.is-close span:nth-of-type(2) {
    opacity: 0;
  }
  &__inner.is-close span:nth-of-type(3) {
    -webkit-animation: active-btn07-bar03 .75s forwards;
    animation: active-btn07-bar03 .75s forwards;
  }
  @-webkit-keyframes active-btn07-bar03 {
    0% {
      -webkit-transform: translateY(0) rotate(0);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0);
    }
    100% {
      -webkit-transform: translateY(-9px) rotate(-45deg);
    }
  }
  @keyframes active-btn07-bar03 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-9px) rotate(0);
    }
    100% {
      transform: translateY(-9px) rotate(-45deg);
    }
  }
}

/* menu */
.l-headerSpNavCont {
  overflow: auto;
  visibility: hidden; /* hidden */
  opacity: 0; /* 0 */
  background-color: #111;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  transition: all .3s;
  pointer-events: none;

  &.is-show {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

/*

mobile nav

*/

/* all */
.l-headerSpNav {
  position: relative;
  width: 100%;
  height: 460px;
  transition: all .3s;

  &__col {
    width: 100%;
    padding: 0 13%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    transition: all .3s;

    &.is-hidden {
      visibility: hidden;
      opacity: 0;

    }
  }

  &List {
    width: 100%;
    padding-top: 20px;

    &__item {
      width: 100%;
      height: 88px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 22px;
        height: 1px;
        background-color: #650000;
        position: absolute;
        right: -3px;
        bottom: 8px;
        transform: rotate(45deg);
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #650000;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.2;
      text-decoration: none;
      color: var(--color-white);
      position: relative;

      &::after {
        position: absolute;
        right: 10%;
        margin: auto;
        content: "";
        vertical-align: middle;
        width: 10px;
        height: 10px;
        border-top: 1px solid #5f5f5f;
        border-right: 1px solid #5f5f5f;
        transform: rotate(45deg);
      }
    }
  }
}

/* product */
.l-headerSpNavProd {
  &__col {
    width: 100%;
    padding: 0 13%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 1;
    transition: all .3s;

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.is-show {
      visibility: visible;
      opacity: 1;
    }
  }

  &List {
    width: 100%;
    padding-top: 20px;

    &__item {
      width: 100%;
      height: 75px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 22px;
        height: 1px;
        background-color: #650000;
        position: absolute;
        right: -3px;
        bottom: 8px;
        transform: rotate(45deg);
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #650000;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.2;
      text-decoration: none;
      color: var(--color-white);
      position: relative;
      padding-top: 25px;

      @media (--mq-sp) {
        font-size: 2rem;
      }

      &::after {
        position: absolute;
        right: 10%;
        margin: auto;
        content: "";
        vertical-align: middle;
        width: 10px;
        height: 10px;
        border-top: 1px solid #5f5f5f;
        border-right: 1px solid #5f5f5f;
        transform: rotate(45deg);
      }
    }
  }
}

/* brand logo */
.l-headerSpLogo {
  padding: 30px 0 28px;
  text-align: center;

  & > img {
    width: 56px;
    height: auto;
  }
}

/* ec shop button */
.l-headerSpShop {
  width: 100%;
  height: 75px;
  background-color: var(--color-white);
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 44px;
    background-color: #c4c4c4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__btn {
    width: calc(100% / 2);

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 75px;
    }
  }

}
