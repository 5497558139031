/* contact */
.p-contact {
  &Sect {
    background-color: #fff;
    position: relative;
    padding: 35px 2.5% 57px;

    @media (--mq-tab) {
      max-width: 960px;
      margin: 0 auto;
      padding: 100px 2.5% 75px;
    }
  }

  &__inner {
    width: 100%;

    @media (--mq-tab) {
      max-width: 750px;
      margin: 0 auto;
    }
  }

  &__lead {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    margin-bottom: 30px;

    @media (--mq-tab) {
      max-width: 900px;
      font-size: 1.5rem;
      line-height: 47px;
      border-top: solid 1px #a80000;
      border-bottom: solid 1px #a80000;
      position: relative;
      margin: 0 auto 106px;

      & br {
        display: none;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -60px;
        left: 50%;
        transform: translateX(-50%);

        width: 13px;
        height: 13px;
        border-top: 2px solid #a80000;
        border-right: 2px solid #a80000;
        transform: rotate(135deg);
      }
    }
  }

  &__zipcode {
    width: 30%;
  }

  &__full {
    width: 100%;
  }

  &__auto {
    margin: 0 10px 10px 0;
  }

  /* 送信ボタン */
  &__btn {
    max-width: 395px;
    margin: 0 auto;

    @media (--mq-tab) {
      margin: 0 0 0 auto;
    }
  }
}

/* complete */
.p-contactComp {
  &Sect {
    background-color: #eaeaea;
    position: relative;
    padding: 35px 2.5% 57px;

    @media (--mq-tab) {
      padding: 100px 2.5% 75px;
    }
  }

  &__lead {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    margin-bottom: 30px;
    padding: 40px 5% 0;

    & br {
      display: none;
    }

    @media (--mq-tab) {
      font-size: 2.4rem;

      & br {
        display: block;
      }

      &--comp {
        font-size: 1.4rem;
        text-align: left;

        & br {
          display: none;
        }
      }
    }
  }
}
