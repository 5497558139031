/* top section title area */
.c-topSectTtl {
  width: 100%;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 35px;

  &--black {
    color: #0f0f0f;
  }

  @media (--mq-tab) {
    margin-bottom: 100px;

    &--movie {
      margin-bottom: 48px;
    }

    &--topics {
      margin-bottom: 94px;
    }
  }

  &__inner {
    max-width: var(--width-max);
    margin: 0 auto;
    /*padding: 29px 0 25px;*/
    text-align: center;

    @media (--mq-tab) {
      /*padding: 100px 0;*/
    }
  }

  &__en {
    display: block;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    position: relative;
    margin-bottom: 20px;

    &::after {
      content: '';
      width: 72px;
      height: 2px;
      background-color: #C40000;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);

      @media (--mq-tab) {
        width: 100px;
      }
    }

    &--about {
      &::after {
        @media (--mq-tab) {
          background-color: var(--color-white);
        }
      }
    }
  }

  &__ja {
    display: block;
    font-size: 1.4rem;
    font-weight: 300;
  }
}
