/* ----------------------------------------
 * ローディング
---------------------------------------- */
.l-loading {
  opacity: 1;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-duration: .64s;

  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #030303;
    transition-property: left;
    transform: skewX(-20deg);
    transition-duration: .64s;
  }

  &::before {
    left: -50%;
  }

  &::after {
    left: 49%;
  }

  &.is-load {
    opacity: 0;

    &::before {
      left: -150%;
    }

    &::after {
      left: 150%;
    }
  }

  &.is-loaded {
    display: none;
  }

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__logoWrap {
    width: 100%;
    text-align: center;
  }

  &__logo {
    display: block;
    max-width: 300px;
    margin: 0 auto;
  }
}
