/*

PC Header

*/
@media (--mq-pc--h) {
  /* SP Header Hidden */
  .l-headerSp {
    display: none;
  }

  .l-header {
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    z-index: 1000;
    padding: 0 2.5%;

    &--pc {
      display: block;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: var(--width-max);
      min-height: 95px;
      margin: 0 auto;
    }

    /* logo */
    &__logo {
      width: 96px;
      height: auto;

      & > img {
        width: 100%;
      }
    }

    /* group */
    &__group {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    /* nav */
    &NavList {
      display: flex;
      align-items: center;
      margin-right: 10px;

      &__item {
        position: relative;

        &::after {
          content: '';
          width: 1px;
          height: 10px;
          display: block;
          background-color: var(--color-white);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        &:last-child::after {
          content: none;
        }
      }

      /* level2 */
      &__Lv2 {
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        position: absolute;
        top: 80px;
        width: auto;
        background: rgba(0, 0, 0, .5);
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        padding: 15px;

        & > li > a {
          display: block;
          color: var(--color-white);
          text-decoration: none;
          white-space: nowrap;
          padding: 15px 5px;
          transition: .3s ease;

          &:hover {
            opacity: .5;
          }
        }
      }

      &__item:hover &__Lv2 {
        top: 95px;
        visibility: visible;
        opacity: 1;
      }

      &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95px;
        font-size: 1.4rem;
        line-height: 1.2;
        text-decoration: none;
        color: var(--color-white);
        transition: .3s ease;
        padding: 0 13px;

        &:hover {
          opacity: .5;
        }
      }
    }

    /* ec logo */
    &EcLogo {
      width: 210px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__block {
        width: 62px;
        height: 53px;

        &:first-child {
          margin-left: 0;
        }
      }

      &__link {}

      &__img {}
    }

    /* contact */
    &Contact {
      color: var(--color-white);
      margin-left: 23px;

      &__ttl {
        display: block;
        font-size: 1.3rem;
        line-height: 1.2;
      }

      &__num {
        display: block;
        font-size: 2rem;
        line-height: 1.2;
        white-space: nowrap;
      }
    }
  }
}

@media (--mq-pc--hl) {
  .l-headerNavList__link {
    padding: 0 32px;
  }
}


