/* topics */
.p-topics {
  &Sect {
    background-color: #eaeaea;
    position: relative;
    padding: 35px 2.5% 57px;

    @media (--mq-tab) {
      padding: 100px 2.5% 75px;
    }
  }

  &__lead {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    margin-bottom: 30px;

    @media (--mq-tab) {
      max-width: 900px;
      font-size: 1.5rem;
      line-height: 47px;
      border-top: solid 1px #a80000;
      border-bottom: solid 1px #a80000;
      position: relative;
      margin: 0 auto 106px;

      & br {
        display: none;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -60px;
        left: 50%;
        transform: translateX(-50%);

        width: 13px;
        height: 13px;
        border-top: 2px solid #a80000;
        border-right: 2px solid #a80000;
        transform: rotate(135deg);
      }
    }
  }

  &List {
    @media (--mq-tab) {
      max-width: 900px;
      margin: 0 auto;
    }

    &__item {
      margin-bottom: 23px;

      @media (--mq-tab) {
        margin-bottom: 46px;
      }

      &:last-child {
        margin-bottom: 43px;
      }
    }

    &__link {
      display: block;
      text-decoration: none;

      @media (--mq-tab) {
       /* display: flex;
        justify-content: space-between;*/
        transition: all .3s;

        &:hover {
          opacity: .5;
        }
      }
    }
  }

  &__thumb {
    width: 100%;
    margin-bottom: 10px;

    & > img {
      width: 100%;
      height: auto;
    }

    @media (--mq-tab) {
      display: none;
    }
  }

  &__cont {
    @media (--mq-tab) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__date {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #898989;
    margin-bottom: 5px;
    position: relative;

    @media (--mq-tab) {
      width: 115px;
      /*border-right: solid 2px #a80000;*/
      font-size: 1.4rem;
      line-height: 2.5;

      &::after {
        content: '';
        display: block;
        width: 2px;
        height: 12px;
        background-color: #a80000;
        position: absolute;
        top: 11px;
        right: 0;
      }
    }
  }

  &__ttl {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: #0f0f0f;
    margin-bottom: 5px;

    @media (--mq-tab) {
      flex: 1;
      font-size: 1.4rem;
      line-height: 2.5;
      padding-left: 23px;
    }
  }

  &__desc {
    font-size: 1.3rem;
    line-height: 1.5;

    @media (--mq-tab) {
      display: none;
    }
  }

  &__btnGroup {
    max-width: 900px;
    margin: 0 auto;

    &--detail {
      max-width: var(--width-max);
    }

    @media (--mq-sp) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__btn {
    margin-bottom: 15px;

    @media (--mq-sp) {
      width: 48%;
    }

    @media (--mq-tab) {
      width: 300px;
    }
  }
}
