/* footer */
.l-footer {
  width: 100%;
  background: linear-gradient(
    -65deg,
    rgba(37, 2, 1, 1) 0,
    rgba(37, 2, 1, 1) 40%,
    rgba(17, 17, 17, 1) 40.1%,
    rgba(17, 17, 17, 1) 100%
  );
  padding: 0 2.5%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 24px;
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #b4b4b4;
    z-index: 10;
  }

  &Top {
    padding-top: 20px;

    @media (--mq-tab) {
      display: flex;
      justify-content: center;
      min-width: 400px;
      max-width: 900px;
      margin: 0 auto 32px;
      flex: 1;
      padding-top: 60px;
    }
  }

  &__inner {
    padding-top: 16px;
    padding-bottom: 15px;

    @media (--mq-tab) {
      padding-top: 26px;
    }
  }

  &InfoBlock {
    margin-bottom: 10px;

    @media (--mq-tab) {
      margin-right: 53px;
    }
  }

  &Logo {
    text-align: center;

    @media (--mq-tab) {
      text-align: left;
    }

    &__img {
      width: 96px;
      height: auto;
      margin: 0 auto 17px;

      @media (--mq-tab) {
        margin: 0 0 5px;
      }
    }

    &__txt {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.2;
      color: var(--color-white);
      margin-bottom: 10px;

      @media (--mq-tab) {
        margin-bottom: 20px;
      }
    }
  }

  &Contact {
    &__txt {
      font-size: 1.3rem;
      line-height: 1.4;
      color: var(--color-white);
      text-align: center;
      margin-bottom: 15px;

      @media (--mq-tab) {
        white-space: nowrap;
        margin-bottom: 5px;
      }
    }

    &__group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (--mq-tab) {
        width: 100%;
      }
    }

    &__btn {
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #383838;
      font-size: 1.3rem;
      text-decoration: none;
      color: var(--color-white);
      padding: 15px 10px;
      position: relative;

      &::after {
        content: '';
        width: 7px;
        height: 7px;
        border-top: 1px solid var(--color-white);
        border-right: 1px solid var(--color-white);
        position: absolute;
        top: 50%;
        right: 13px;
        transform: rotate(45deg) translateY(-50%);
      }

      @media (--mq-tab) {
        text-align: center;
        padding: 4px 10px;

        &--mail {

        }

        &--tel {
          pointer-events: none;

          &::after {
            content: none;
          }
        }
      }
    }
  }

  /* nav */
  &Nav {
    display: none;

    @media (--mq-tab) {
      display: flex;
      justify-content: space-between;
      min-width: 50%;
    }

    &List {
      max-width: 100%;
      border-left: solid 1px #650000;
      padding: 0 6% 0 3%;

      &__item {
        margin-bottom: 13px;
      }

      &__link {
        font-size: 1.2rem;
        line-height: 1.2;
        text-decoration: none;
        color: var(--color-white);
        transition:  transform .6s ease, opacity .2s ease;

        &:hover {
          opacity: .5;
        }
      }
    }
  }

  /* ec logo */
  &EcLogo {
    display: none;

    @media (--mq-tab) {
      width: 220px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 20px;

      &__block {
        width: 56px;
        height: 56px;

        &:first-child {
          margin-left: 0;
        }
      }

      &__link {}

      &__img {}
    }
  }

  /* text link */
  &TxtLinkList {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    &__item {
      width: 48%;
      text-align: center;

      @media (--mq-tab) {
        width: auto;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__btn {
      text-decoration: none;
      color: var(--color-white);
    }
  }

  /* copyright */
  &__copy {
    font-size: 10px;
    line-height: 1.2;
    color: #989898;
    text-align: center;
  }
}
