/* ----------------------------------------
 * font
---------------------------------------- */
.u-fontN {
  font-family: 'Noto Sans JP', sans-serif;
}

.u-fontP {
  font-family: 'Prompt', sans-serif;
}

/* ----------------------------------------
 * 表示
---------------------------------------- */
.u-show {
  &--pc {
    display: block;

    @media (--mq-sp) {
      display: none;
    }
  }

  &--sp {
    display: none;

    @media (--mq-sp) {
      display: block;
    }
  }
}

/* ----------------------------------------
 * 幅
---------------------------------------- */
.u-wid--** { width: 100%; }

/* ----------------------------------------
 * テキスト位置
---------------------------------------- */
.u-txtC { text-align: center; }
.u-txtR { text-align: right; }
.u-txtL { text-align: left; }

/* ----------------------------------------
 * 縦位置
---------------------------------------- */
.u-vaMid { vertical-align: middle; }

/* ----------------------------------------
 * ハイライト
---------------------------------------- */
.u-hl {
  display: inline-block;
  background-color: #ffdb76;
  border-radius: 4px;
  padding: .2em .5em;
  margin: .5em 0;
}
