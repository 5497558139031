@charset 'utf-8';

/* ========================================
 * Foundation
======================================== */
@import 'foundation/_variable.pcss'; /* 変数の定義 */
@import 'foundation/_media.pcss'; /* メディアクエリ */
@import 'ress/dist/ress.min.css'; /* リセット */
@import 'luminous-lightbox/dist/luminous-basic.min.css'; /* リセット */
@import 'foundation/vendor/**/*.pcss'; /* 外部ファイル調整 */
@import 'foundation/_base.pcss';

/* ========================================
 * Layout
======================================== */
@import 'layout/**/*.pcss';

/* ========================================
 * Object
======================================== */
/* ----------------------------------------
 * Component
---------------------------------------- */
@import 'object/component/**/*.pcss';

/* ----------------------------------------
 * Project
---------------------------------------- */
@import 'object/project/**/*.pcss';

/* ----------------------------------------
 * Utility
---------------------------------------- */
@import 'object/utility/**/*.pcss';
