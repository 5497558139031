/* form */
.wpcf7-form-control-wrap {
  width: 100%;
}

.c-form {
  &Block {
    margin-bottom: 30px;

    @media (--mq-tab) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__ttl {
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 5px;

    @media (--mq-tab) {
      width: 26%;
      line-height: 38px;
    }
  }

  &__cont {
    width: 100%;

    @media (--mq-tab) {
      width: 70%;
    }
  }

  &__subTtl {
    display: inline-block;
    margin-right: 5px;
    white-space: nowrap;
    line-height: 40px;
    width: 3em;
    text-align: right;
  }

  &__note {
    color: #999;
  }

  &__require {
    display: inline-block;
    color: #bf2121;
    margin-left: 10px;
  }

  &__notice {
    display: block;
    color: #bf2121;

    @media (--mq-tab) {
      & br {
        display: none;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;

    &--half {
      width: 48%;
    }

    & span {
      display: block;
    }
  }

  &__fwrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  &Text {
    appearance: none;
    background-color: var(--color-white);
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 0;
    color: inherit;
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: .4em .8em;
    width: 100%;

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.32);
      box-shadow: none;
      outline: none;
    }

    @media (--mq-tab) {

    }
  }

  &Textarea {
    appearance: none;
    background-color: var(--color-white);
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 0;
    color: inherit;
    font-family: inherit;
    font-size: 1em;
    height: 100px;
    padding: 0.4em 0.8em;
    width: 100%;

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.32);
      box-shadow: none;
      outline: none;
    }

    @media (--mq-tab) {}
  }

  &BorderBtn {
    padding: 7px 30px;
    border-radius: 20px;
    border: solid 1px #ccc;
    background-color: #fff;
    white-space: nowrap;
  }

  select::-ms-expand {
    display: none;
  }

  &Select {
    appearance: none;
    margin-bottom: 20px;
    padding: 7px 30px;
    border-radius: 20px;
    border: solid 1px #ccc;
    background-color: #fff;
    white-space: nowrap;
  }

  &Btn {

    @media (--mq-tab) {}
  }

  &__hyphen {
    display: inline-block;
    padding: 0 5px;
    line-height: 40px;
  }

  &__ex {
    display: block;
    padding: 5px 0;
  }
}
