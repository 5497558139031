/* primary button */
.c-primaryBtn {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    -60deg,
    rgba(213, 0, 0, 1) 0,
    rgba(213, 0, 0, 1) 50%,
    rgba(196, 0, 0, 1) 50%,
    rgba(196, 0, 0, 1) 100%
  );
  padding: 15px 25px 15px 20px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  color: var(--color-white);
  text-decoration: none;
  transition: all 0.5s;

  & span {
    display: block;
    z-index: 1;
    position: relative;
    font-size: 1.6rem;
  }

  &::before {
    content: '';
    width: 14px;
    height: 100%;
    background-color: rgba(101, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
  }

  &::after {
    content: '';
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--color-white);
    border-right: 1px solid var(--color-white);
    position: absolute;
    top: 50%;
    right: 20px;
    transform: rotate(45deg) translateY(-50%);
  }

  &--topicsPrev {
    &::before {
      content: none;
    }

    &::after {
      content: '';
      width: 7px;
      height: 7px;
      border-top: 1px solid var(--color-white);
      border-right: 1px solid var(--color-white);
      position: absolute;
      top: 40%;
      left: 20px;
      transform: rotate(225deg) translateY(-50%);
    }
  }

  &--topicsNext {
    &::before {
      content: none;
    }
  }

  @media (--mq-tab) {
    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}
