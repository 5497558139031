.p-prodDetail {
  &Sect {
    background-color: #292929;
    padding: 94px 2.5% 32px;

    @media (--mq-tab) {
      padding: 250px 2.5% 90PX;
    }
  }

  &__lead {
    font-size: 1.4rem;
    line-height: 1.7;
    color: #fff;
    margin-bottom: 14px;

    @media (--mq-tab) {
      max-width: 630px;
      margin-bottom: 30px;
    }
  }

  /* slider */
  &Slider {
    margin-bottom: 16px;

    @media (--mq-tab) {
      margin-bottom: 45px;
    }

    &__main {
      margin-bottom: 10px;

      @media (--mq-tab) {
        margin-bottom: 30px;
      }

      & .swiper-wrapper {
        @media (--mq-tab) {
          text-align: center;
        }
      }
    }

    &__thumb {
      @media (--mq-pc) {
        width: 552px;
        margin: 0 0 0 auto;
      }

      & .swiper-wrapper {
        display: flex;
        /*justify-content: flex-end;*/
      }

      & .swiper-slide {
        /*width: 22.2%;
        margin: 1%;*/
      }
    }

    /* 矢印 */
    &__arwPrev {
      width: 40px;
      height: 50px;
      background-image: none !important;
      position: relative;
      display: flex;
      align-items: center;
      outline: none;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        position: absolute;
        left: 10px;
        transform: rotate(225deg);
      }

      &.swiper-button-disabled {
        &::before {
          content: none;
        }
      }
    }

    &__arwNext {
      width: 40px;
      height: 50px;
      background-image: none !important;
      position: relative;
      display: flex;
      align-items: center;
      outline: none;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        position: absolute;
        right: 10px;
        transform: rotate(45deg);
      }

      &.swiper-button-disabled {
        &::before {
          content: none;
        }
      }
    }
  }

  /* 価格 */
  &__price {
    font-size: 3.3rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: right;
    color: var(--color-white);
    padding: 0 32px 10px;

    @media (--mq-tab) {
      display: none;
    }
  }

  /* 購入ボタン */
  &__btn {
    text-align: right;
    width: 298px;
    margin: 0 0 0 auto;
  }

  /* 商品情報 */
  &__desc {
    margin-bottom: 12px;
    line-height: 1.8;

    &--sp {
      @media (--mq-tab) {
        display: none;
      }
    }

    &--pc {
      display: none;

      @media (--mq-tab) {
        display: block;
        font-size: 1.4rem;
        line-height: 2.8;
        color: var(--color-white);
        margin-bottom: 32px;
      }
    }
  }

  &BtmSect {
    background-color: #ccc;
    padding: 12px 2.5% 18px;

    @media (--mq-tab) {
      padding: 110px 2.5% 104px;
    }
  }

  /* movie */
  &__movie {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 1075px;
    margin: 0 auto;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.swiper-container{
  text-align: center;
}
.swiper-container .swiper-slide img{
  max-width: 100%;
  width: 100%;
  height: auto;
}
.prettyprint{
  border: none;
  background: #fafafa;
  color: #697d86;
}
#thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
#thumbs .swiper-slide {
  width: 20%;
  height: 100%;
  opacity: 0.2;
  cursor: pointer;
}
#thumbs .swiper-slide-active {
  opacity: 1;
}
