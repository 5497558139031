/* page hero */
.c-pageHero {
  width: 100%;
  height: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &--about {
    background-image: url(../img/common/title_bg_about_sp.png);


    @media (--mq-tab) {
      background-image: url(../img/common/title_bg_about_pc.png);
    }
  }

  &--topics {
    background-image: url(../img/common/title_bg_topics_sp.png);


    @media (--mq-tab) {
      background-image: url(../img/common/title_bg_topics_pc.png);
    }
  }

  &--contact {
    background-image: url(../img/common/title_bg_contact_sp.png);


    @media (--mq-tab) {
      background-image: url(../img/common/title_bg_contact_pc.png);
    }
  }

  @media (--mq-tab) {
    height: 726px;
    padding-top: 96px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 37px;
    background-color: var(--color-white);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (--mq-tab) {
      height: 195px;
    }
  }

  &::before {
    top: 7px;

    @media (--mq-tab) {
      top: 137px;
    }
  }

  &::after {
    bottom: 7px;

    @media (--mq-tab) {
      bottom: 41px;
    }
  }

  &__ttl {
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
    color: var(--color-white);

    @media (--mq-tab) {
      font-size: 4.5rem;
    }
  }
}
