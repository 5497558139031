/* topics detail */
.p-topicsDetail {
  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (--mq-tab) {
      flex-direction: row;
      flex-wrap: inherit;
      align-items: flex-end;
      margin-bottom: 70px;
      position: relative;
    }

    &::after {
      @media (--mq-tab) {
        content: '';
        width: 72px;
        height: 2px;
        background-color: #C40000;
        position: absolute;
        font-size: 2.4rem;
        line-height: 1.2;
        left: 0;
        bottom: -36px;
        transform: translateX(0%);
      }
    }
  }

  &__date {
    display: block;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #898989;
    margin-bottom: 5px;
    order: 1;

    @media (--mq-tab) {
      order: 2;
      font-size: 1.2rem;
      line-height: 2;
      margin-bottom: 0;
      margin-left: 1em;
    }
  }

  &__ttl {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    color: #0f0f0f;
    margin-bottom: 15px;
    order: 2;

    @media (--mq-tab) {
      font-size: 2.4rem;
      order: 1;
      margin-bottom: 0;
    }
  }

  /* entry content */
  &__entryCont {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 40px;

    & .wp-block-image {
      max-width: 768px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }

    & .wp-block-gallery {
      max-width: 768px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 1.8em;
    }

    & .wp-block-video {
      max-width: 768px;
      margin-right: auto;
      margin-left: auto;
    }

    & .wp-block-embed-twitter {
      max-width: 768px;
      margin-right: auto;
      margin-left: auto;
    }

    /* youtube */
    & .wp-block-embed-youtube {
      max-width: 768px;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;

      @media (--mq-tab) {
        width: 768px;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .wp-block-embed-youtube iframe,
    .wp-block-embed-youtube object,
    .wp-block-embed-youtube embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media (--mq-tab) {
        position: static;
      }
    }

    & img {
      max-width: 100%;
      height: auto;
    }

    & p,
    & .wp-block-cover,
    & .wp-block-cover-image {
      margin-bottom: 1.8em;
    }

    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 1em;
    }

    & ul {
      list-style-type: disc;
      margin-left: 1.5em;
      margin-bottom: 1.8em;
    }

    & ul.blocks-gallery-grid {
      list-style-type: none;
      margin-left: 0;
    }
  }

  /* 一覧に戻る */
  &__btn {
    width: 80%;
    margin: 0 auto;

    @media (--mq-tab) {
      max-width: 400px;
    }
  }
}
