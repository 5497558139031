/* product */
.p-prodSect {
  background-color: #292929;
  padding: 94px 2.5% 32px;

  @media (--mq-tab) {
    padding: 250px 2.5% 90PX;
  }
}

/* list */
.p-prodListSect {
  padding: 20px 2.5%;

  @media (--mq-tab) {
  }
}

.p-prodListWrap {
  @media (--mq-tab) {
    margin-bottom: 15px;
  }
}

.p-prodList {
  @media (--mq-tab) {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-prodList__item {
  margin-bottom: 20px;
  position: relative;

  @media (--mq-tab) {
    width: 22.2%;
    margin-right: 3.56%;
    margin-bottom: 48px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  &.is-hidden {
    display: none;
  }
}

.p-prodList__new {
  display: inline-block;
  background-color: #C40000;
  color: var(--color-white);
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0 10px;
  z-index: 100;
}

.p-prodList__link {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 170px;

  @media (--mq-tab) {
    height: auto;
    padding-top: 100%;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    position: relative;
    z-index: 10;
    transition: all .5s;

    @media (--mq-tab) {
      visibility: hidden;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  & > img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.p-prodList__group {
  position: absolute;
  right: 16px;
  bottom: 16px;
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  z-index: 20;
  transition: all .5s;

  @media (--mq-tab) {
    right: inherit;
    bottom: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.p-prodList__ttl {
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1.2;

  @media (--mq-tab) {
    font-size: 2.2rem;
  }
}

.p-prodList__price {
  display: none;

  @media (--mq-tab) {
    font-size: 2.2rem;
    display: block;
  }
}

/* hover */
@media (--mq-tab) {
  .p-prodList__link:hover {
    &::after {
      visibility: visible;
      opacity: 1;
      background-color: rgba(101, 0, 0, .68);

      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    & .p-prodList__group {
      visibility: visible;
      opacity: 1;
      top: 50%;
      right: inherit;
      bottom: inherit;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.p-prodList__btn {
  max-width: 298px;
  margin: 0 auto;

  @media (--mq-tab) {
    margin: 0 0 0 auto;
  }
}

/* pager */
.p-prod {
  &__btnGroup {
    max-width: 900px;
    margin: 0 auto;

    &--detail {
      max-width: var(--width-max);
    }

    @media (--mq-sp) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__btn {
    @media (--mq-sp) {
      width: 48%;
    }

    @media (--mq-tab) {
      width: 300px;
    }
  }

  &Pager {
    display: flex;
    align-items: center;
    margin: 0 20px;

    & > a {
      font-size: 1.4rem;
      color: var(--color-white);
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border: solid 1px var(--color-white);
      margin-right: 3px;
      text-decoration: none;
    }

    /* current */
    & > span {
      font-size: 1.4rem;
      color: var(--color-white);
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border: solid 1px var(--color-white);
      margin-right: 3px;
    }
  }

  /* moreボタン */
  &More {
    width: 298px;
    margin: 0 auto;
  }
}
